body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: auto;
  overflow-y: hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#lateral{
  display: flex;
  min-height: 100vh;
}

aside  {
  padding: 3rem;
}
#grafica{
  
  height: 450px;
  width:  400px;
}

#RPgrafica{
  
  height: 82px;
  width:  230px;
}
#RPApoGrafica{  
  height: 6rem;
  width:  12rem;
}
#graficaHorBar{
  height: 350px;
  width:  350px;
}

.elgrande{
  display: flex;  
  flex-direction:  column;
  flex-wrap: wrap;
  
}
.separacion{
  display: flex; 
}
.color{
 background-color: #F3A80B;
 color: white; 
}
#miside-bar{
  display: flex;
  /*background-color: green; */
  height: 39.8rem;
  
}

#principal-centro
{
  display: flex;  
  /* background-color: red; */
  width: 100%; 
  flex-direction:  column;
  height: auto;
  
}
#cabecera{
  display: flex;
  
  align-self: flex-start;
  align-items: flex-end;


}
.marcoT{
  padding: 1rem 1.5rem;
}
.marcoGrafica{
  padding: 0.10rem 1.5rem;
}
.marcoTexto{
  padding: 0.5rem 1.5rem;
}
#cabecera-cont{
  display: flex;
  /* background-color: blue; align-items: flex-end; padding-left: 0%;*/
  
  align-content: center;    
  width: 30%;
}
#cabecera-cont2{
  display: flex;
  align-self: center;
  justify-content: center;
  width: 40%;
}
#cabecera-cont3{
  display: flex;  
  justify-content: flex-end;
  width: 30%;
}

.fuenteBar{
  color:white;
}
.navBar{
display: flex;

 width: 100%; 


}
.contenido-coordi
{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content:center;
  height: 39.8rem;
}

#etiqRoja{
  background-color:#FF0505E8;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 1.5em;
  justify-content: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: .75em;
  white-space: nowrap;
}

#etiqVerd{
  background-color:#1CC63D;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 1.5em;
  justify-content: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: .75em;
  white-space: nowrap;
}
#etiqNar{
  background-color:#F3A80B;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 1.5em;
  justify-content: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: .75em;
  white-space: nowrap;
}
#etiqBlanca{
    
  border-radius: .375em;
  align-items: center;
  height: 1.5em;
  justify-content: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: .75em;
  white-space: nowrap;
}

#ajustarTitulo{ 
  text-align: center;
  
}
#ajustarGrafica{  
  padding-top: 0rem;
  margin-top: 1rem;
  text-align: center;
  /* 
  align-items: flex-end;  
  padding-left: .75em;
  justify-content: flex-end; 
  justify-content: center;
  padding-right: .75em; 
  white-space: nowrap;*/
}

#login{
  justify-content: center;    
}

#imgLogo{
  display: flex;
  
  height: 48px;
  width: 96px;
  margin-left: 0%;
}

#assideLateral{
  padding: 1rem;
}

#menu-is-active {
  background-color: #3273DC;
  color: #fff;
}
.alinear
{
  height: 2em;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: .75em;
  white-space: nowrap;
  font-size: .9rem;
}

.alinear2
{
  height: 1rem;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: .75em;
  white-space: nowrap;
  font-size: .8rem;
}
#contentTabGraf{
  display:flex;
  flex-direction: column;
  /* background-color: blue; */
}
#contentGrafica{
  display:flex;
  flex-direction: row;
  height: 375px;
  /*background-color: yellow; 
  justify-content:space-around;
   background-color: yellow; */
}

/*#grafica{

  height: 400px;
  width: 400px;
}*/

#etiqRojaTab{
  background-color:#FF0505E8;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 1.1rem;
  justify-content: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: .75em;
  white-space: nowrap;
  padding-bottom: .1rem;
}
#etiqVerdTab{
  background-color:#1CC63D;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 1.1rem;
  justify-content: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: .75em;
  white-space: nowrap;
  padding-bottom: .1rem;
}
#etiqNaraTab{
  background-color:#F3A80B;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 1.1rem;
  justify-content: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: .75em;
  white-space: nowrap;
  padding-bottom: .1rem;
}

.tablaTR{
  padding: 0rem;
}

.tab-posicion{
  padding: 0rem;
}
#espacioGraf{
  /* display: flex; 
  justify-content: flex-end;
  background-color: #1CC63D;  
   */   
  
  align-items: flex-end;
  padding: 0.10rem 1.75rem;
}

#espacioGraf2{    
  align-items: flex-end;
  padding: 0.10rem 3.5rem;
}




#etiqRojaGfS{  
  background-color:#FF0505E8;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 2.5rem;
  justify-content: center;
  text-align: center;
  /*line-height: 1.5;*/
  padding-left: .75rem;
  padding-right: .75rem;
  white-space: nowrap;
}

#etiqNaraGfS{  
  background-color:#F3A80B;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 2.5rem;
  justify-content: center;
  text-align: center;
  /*line-height: 1.5;*/
  padding-left: .75rem;
  padding-right: .75rem;
  white-space: nowrap;
}

#etiqVerdGfS{  
  background-color:#1CC63D;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 2.5rem;
  justify-content: center;
  text-align: center;
  /*line-height: 1.5;*/
  padding-left: .75rem;
  padding-right: .75rem;
  white-space: nowrap;
}

#etiqBlanGfS{  
  
  border-radius: .375em;
  align-items: center;
  height: 2.5rem;
  text-align: center;
  justify-content: center;
  /*line-height: 1.5;*/
  padding-left: .75rem;
  padding-right: .75rem;
  white-space: nowrap;
}


.cabeza-promotor{
  display: flex;
  flex-direction: column;
  /* background-color: #1CC63D */
  /*background-color: #1CC63D;*/
}

.select-promotor{
  display: flex;
  flex-direction: row;
  /* background-color: #3273DC; */
  /*background-color: #3273DC;
    justify-content: space-between;
  */
  
  align-self: center;
}
.selTam-promo{
  display: block;
  width: 20rem;
  
}
.selTam-promo2{
  display: block;
  width: 10rem;
  
}
.tamLetra{
  font-size: 15px;
  font-weight: 700;
}
.tamLetra2{
  font-size: 13px;
}


#etiqRojaProm{  
  background-color:#FF0505E8;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 1.5rem;
  justify-content: center;
  text-align: center;
  /*line-height: 1.5;*/
  padding-left: .5rem;
  padding-right: .5rem;
  white-space: nowrap;
}
#etiqVerdProm{  
  background-color:#1CC63D;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 1.5rem;
  justify-content: center;
  text-align: center;
  /*line-height: 1.5;*/
  padding-left: .5rem;
  padding-right: .5rem;
  white-space: nowrap;
}
#etiqNaraProm{  
  background-color:#F3A80B;
  color :white;
  border-radius: .375em;
  align-items: center;
  height: 1.5rem;
  justify-content: center;
  text-align: center;
  /*line-height: 1.5;*/
  padding-left: .5rem;
  padding-right: .5rem;
  white-space: nowrap;
}

#etiqBlanProm{  
  
  border-radius: .375em;
  align-items: center;
  height: 1.5rem;
  text-align: center;
  justify-content: center;
  /*line-height: 1.5;*/
  padding-left: .5rem;
  padding-right: .5rem;
  white-space: nowrap;
}


.contenido-tbPromov
{
  display: flex;
  padding: 0%;
  flex-direction: row;
  /*width: 40rem;
  background-color: #1CC63D;*/
  height: 35rem;
  
}

.tabs.is-very-small {
  font-size: .75rem;
  padding: 0.15rem;
  font-weight: 700;
}
.TabPrincipal{
    font-size: 10px;
    padding: 0.15rem;
    font-weight: 700;
}

.UserPrincipal{
  font-size: 8px;
  padding: 0.15rem;
  font-weight: 500;
}
.HeroAjuste{
  padding: 0.55rem;
}

.hero.is-mas-small .hero-body {
  padding: 0.55rem;
}

.menu-list-a {
  padding: 0.55rem;
}

.menu-list.is-mas-small{
  line-height: 0.8;
}
.Span-is-mas-small{
  font-size: 8px;
  font-weight: 600;
}
.MenuListCenter {
  margin: auto;
  width: 50%;
  border: 0.1px solid green;
  padding: 0px;
}

#card-img-izq
{
  display: flex;
  flex-direction: row;
}

#card-img-der
{
  display: flex;
  flex-direction: column;
}

#fotoCr{

  height: 200px;
  width: 200px;
  margin-left: 0%;
}

.tablaprogra{
  justify-self: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
#axColor{
  background-color: aqua;
}


.card-tabla{
  /*justify-content: space-around;*/
  display: flex;
  flex-direction: row;
  
  width: 100%;
}

.cent-promot
{
  display: flex;
  flex-direction: column;
}

#tamTabla
{ overflow: auto;
  height: 29rem;
  width: 22rem;
}
#tamTablaPromotorBusqueda
{ overflow: auto;
  height: 27.5rem;
  width: 19rem;
}
#tamTablaUnPromotorBusqueda
{ overflow: auto;
  height: 27.5rem;
  width: 16rem;
}
#tamTablaPromovidosBusqueda
{ overflow: auto;
  height: 27.5rem;
  width: 63rem;
}
#tamTablaPromotoresReportes
{ overflow: auto;
  height: 25rem;
  width: 20rem;
}
#tamTablaPromovidosReportes
{ overflow: auto;
  height: 28rem;
  width: 58rem;
}
#tamTablaPromovidosApoyo
{ overflow: auto;
  height: 29rem;
  width: 58rem;
}

#tamTablaPromovReportApoyo
{ overflow: auto;
  height: 27.5rem;
  width: 57rem;
}


#tamTablaMapa
{ overflow: auto;
  height: 27.5rem;
  width: 20rem;
}
#mitabla2{

  overflow-y: auto;
  height: 25 rem;
}

#tamTablaProgramas
{ overflow-y: auto;  
  height: 25rem;
  
}
#tamTablaPromomtores
{ overflow: auto;
  height: 26rem;
  width: 22rem;
}



.letraColorSecc{
  background-color: #3273DC;
  color: white;
  border-radius: .5em;
  align-items: center;
  height: 1.5rem;
  justify-content: center;
  text-align: center;
  /*line-height: 1.5;*/
  padding-left: .5rem;
  padding-right: .5rem;
  white-space: nowrap;
}

.menu-list a.es-activo {
  background-color: #3273DC;
  color: black;
}


.hero.es-partido {
  background-color: #3273DC;
  display: flex;
  align-items:stretch;
   color: black;
   width: 100%;
}

.tabs li.es-activo a {
  border-bottom-color: #3273DC;
  background-color: black;
  color: black;
}

#fondoColor
{
  border-bottom-color: #3273DC;
  background-color:  #3273DC;
  color: black;

}

.button.is-sistema {
  background-color: #3273DC;
  border-color: transparent;
  color: white;
}


.help.is-sistema {
  color: #3273DC;
}

.button.is-aceptar {
  background-color: #3273DC;
  border-color: transparent;
  color: #fff;
}

.progress.is-sistema:indeterminate {
  background-image: linear-gradient(to right,#3273DC 30%,#ededed 30%);
}


/*
.button.is-hovered, .button:hover {
    border-color: #b5b5b5;
    color: #363636;
}*/

.table tr.is-seleccionado th {
  background-color: #3273DC;
  color: #fff;
}

.table tr.is-sobrepuesto {
  background-color: #3273DC;
  color: #fff;
}
/*
.hero.is-link .tabs.is-boxed a, .hero.is-link .tabs.is-seleccionado a {
  color: #3273DC;
}*/




.mi-button {
  background-color: #fff;
  border-color: #dbdbdb;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(.5em - 1px);
  text-align: center;
  white-space: nowrap;
}

.mi-button.is-sistema {
  background-color: #3273DC;
  border-color: transparent;
  color: white;
  border-radius: .375em;
}



#etiqBlancaMapa{
  border-radius: .375em;
  align-items: center;
  height: 1.5em;
  justify-content: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: .75em;
  white-space: nowrap;
  width: 2rem;
}
.mi-contenedor{

    background-color: #fff;
    border-radius: .25rem;
    box-shadow: 0 0.5em 1em -0.125em rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.02);
    color: #4a4a4a;
    
  
}
#micardDireccion
{ 
  width: 32rem;
}



#TablaIncidencias
{ overflow: auto;
  height: 19rem;
  width: 15.5rem;
}
#TablaPrepVotos
{ overflow: auto;
  height: 16.8rem;
  width: 17.1rem;
}
#TablaPrepVotSecc
{ overflow: auto;
  height: 16.5rem;
  width: 19rem;
}
#TablaFotos
{ overflow: auto;
  height: 12rem;
  width: 10rem;
}

.alinear3
{
  height: 2em;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: 19rem;
  white-space: nowrap;
  font-size: .9rem;
}
.alinear4
{
  height: 2em;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: 22rem;
  white-space: nowrap;
  font-size: .9rem;
}

.alinear6
{
  height: 2em;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: 4rem;
  white-space: nowrap;
  font-size: .9rem;
}
.marcoGraficaIncid{
  padding: 0.10rem 1.5rem;
}

#graficaHorBarIncidencia{
  height: 350px;
  width:  20rem;
}
#Incgrafica{
  
  height: 25rem;
  width:  28rem;
}
#PrepVotosgrafica{  
  height: 20rem;
  width:  55rem;
}
#Prepgrafica{
  
  height: 22.5rem;
  width:  52rem;
}
#TablaIncidenciaDetalle
{ overflow: auto;
  height: 27.5rem;
  width: 43.5rem;
}

.etiqColorSinSecc{
  background-color: #3273DC;
  color: white;
  border-radius: .5em;
  align-items: center;
  height: 1.5rem;
  justify-content: center;
  text-align: center;
  /*line-height: 1.5;*/
  padding-left: .5rem;
  padding-right: .5rem;
  white-space: nowrap;
}

.etiqColorSecc{
  background-color: white;
  color: #3273DC;
  border-radius: .5em;
  align-items: center;
  height: 1.5rem;
  justify-content: center;
  text-align: center;
  /*line-height: 1.5;*/
  padding-left: .5rem;
  padding-right: .5rem;
  white-space: nowrap;
}

.marcoGrafica3{
  padding: 0.10rem 1.5rem;
}
.marcoTexto3{
  padding: 0.03rem 14rem;
}


.marcoGraficaPrepSecc{
  padding: 0.04rem 1.5rem;
}




#TablaMoviliSecciones
{ overflow: auto;
  height: 25rem;
  width: 16rem;
}
.alinear5
{
  height: 2em;
  justify-content: center;
  align-items: center;
  line-height: 1.5;
  padding-left: .75em;
  padding-right: 1rem;
  white-space: nowrap;
  font-size: .9rem;
}


#TablaMoviliPromv
{ overflow: auto;
  height: 24.5rem;
  width: 44rem;
}


.PrepVotosCards{  
  height: 4rem;
  width:  8rem;
  margin-top: .1rem;
}


#Movgrafica{
  
  height: 25rem;
  width:  18rem;
}


.input.mi-input {
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  max-width: 80%;
  width: 35%;
  
}



.micard {
  background-color: #fff;
  border-radius: .25rem;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
}


.micard2 {
  background-color: #fff;
  border-radius: .25rem;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
  width: 10rem;
}